/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Image from "../images/sleeping-dog.svg"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children }) => (
  <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />   
      <link href="https://fonts.googleapis.com/css2?family=Nunito&family=Ubuntu&display=swap" rel="stylesheet" />
    </Helmet>

    <nav>
      <ul>
        <li>
          <Link to="/">
            <img
              src={Image}
              width={70}
              alt="restful coder logo"
            />
          </Link>
        </li>
        <li><Link to="/">restful coder</Link></li>
      </ul>
      <ul>
        <li><Link to="/health">health</Link></li>
        <li><Link to="/training">training</Link></li>
        <li><Link to="/shop">shop</Link></li>
      </ul>
    </nav>

    <main>
      {children}
    </main>

    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
