import * as React from "react"
import Image from "../images/mountains.svg"

const Footer = () => (
  <footer style={{ textAlign: "center" }}>
    <p>
      <img
        src={Image}
        width="400"
        alt="Pacific NW mountain and stream illustration"
      />
    </p>

    <br />

    <p>
      <small>
        ©{new Date().getFullYear()} - Made in Oregon
      </small>
    </p>
  </footer>
);

export default Footer;